/*
 * @Author: WYY
 * @Description:判断题
 * @ModuleName: 判断题
 * @Date: 2018-09-25 11:47:46
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-05 15:10:46
 */
<style lang="less" scoped>
</style>
<template>
    <div class="T_box">
        <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
        <!-- <video :id="`myElement`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
        </video> -->
        <div class="T_content">
            <div class="Unit_d">
                <div class="Unit_con">
                    <!-- <div class="ShuiYinRight"> 中国民用航空西北地区空中交通管理局 </div> -->
                    <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                    <div class="Second_title">
                        <div class="Audio_play">
                            <span class="t1"> No.{{parseInt(questionIndex)+1}}/{{questionAll}}  </span>
                            <AudioPlayer ref="stemAudioPlayer"   @audioEndedOver="PlayerComplete"  :theUrl="AudioPlayerUrl" :config="AudioPlayerConfig" />
                            <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                <img :src=" require( '@/assets/shuoming.png')" alt="">
                                <span>Directions</span>
                            </div>
                        </div>
                    </div>

                    <div class="Question_box clearfix">
                        <div :class="['question_des',(threeActive=='')?'active':'']">
                            <div :class="['content_t',complate?'complate':'']">
                                <div class="Soption_box">
                                    <ul class="Soption">
                                        <li  :class="[(check=='true')?'checked':'',check=='true'?(check==answer?'True':'False'):'']" @click="()=>{  check = 'true';confirm() }">
                                            <span></span>
                                            <p>
                                                <span class="optiona"></span>
                                                <label class="option" >  <span>True</span>  
                                                <img src="../../assets/true.png" v-if="check=='true'&&check==answer"/>
                                                <img src="../../assets/false.png" v-if="check=='true'&&check!=answer" />
                                                </label>
                                            </p>
                                        </li>
                                        <li :class="[(check=='false')?'checked':'',check=='false'?(check==answer?'True':'False'):'']" @click="()=>{  check = 'false';confirm() }">
                                            <span></span>
                                            <p>
                                                <span class="optiona"></span>
                                                <label class="option" > <span>False</span> 
                                                <img src="../../assets/true.png" v-if="check=='false'&&check==answer"/>
                                                <img src="../../assets/false.png" v-if="check=='false'&&check!=answer"/>
                                                </label>
                                            </p>
                                        </li>
                                     </ul>
                                </div>

                                </div>
                            <!-- <div class="title">True or False</div> -->
                            <!-- <div :class="['content_t content_t_judgment',complate?'complate':'']">
                                <button :class="[(check=='true'&&!complate)?'check':'',(check=='true'&&complate)?'checked':'',(check==answer&&complate)?'true':'false']"
                                @click="()=>{
                                    complate = false;
                                    check = 'true';
                                    }"> True </button>
                                <button :class="[(check=='false'&&!complate)?'check':'',(check=='false'&&complate)?'checked':'',(check==answer&&complate)?'true':'false']" @click="()=>{
                                    complate = false;
                                    check = 'false';
                                    }"> False </button>
                                <span v-if="Pattern!='测试模式'" class="confirm" @click="confirm()"> confirm </span>
                            </div> -->


                        </div>


                        <div class="Text" v-show="threeActive=='TEXT'">
                            <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div style="height:100%;">
                                <div class="t_content" style="border:none">
                                    <span v-if="!!questionData.questionText&&questionData.questionText!='null'&&questionData.questionText!=''">Subject text</span>
                                    <div v-if="!!questionData.questionText&&questionData.questionText!='null'&&questionData.questionText!=''"  v-html="questionData.questionText"></div>
                                </div>
                            </div>
                        </div>
                        <div class="Notes" v-show="threeActive=='NOTES'">
                            <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                            <div class="t_content">
                                <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                <button class="save" @click="SaveNotes"> Save </button>
                            </div>
                        </div>
                        <!-- key -->
                        <div class="Text" v-show="threeActive=='KEY'">
                            <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div class="t_content" v-html="AnswerKey"></div>
                        </div>
                    </div>
                    <!-- 作答倒计时 -->
                    <div class="CountDown" v-show="ProgressBar.show">
                        <el-progress type="circle" :percentage="ProgressBar.width"  :show-text='false' status="success" :width='50' :stroke-width='3'></el-progress>
                        <span class="count">{{ProgressBar.duration}}</span>
                    </div>
                </div>
            </div>
            <div class="T_option">
                <div class="Unit_option">
                    <div class="btn_list">
                        <!-- back -->
                        <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                        <!-- next -->
                        <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>
                        <!-- <span v-if="Pattern!='测试模式'&&ScoreShow&&ComplateTest=='1'&&isJudgment=='1'" style="font-size:18px;display:inline-block;vertical-align:middle;"> 等级：{{this.TiScore}} </span>
                        <span v-if="!ScoreShow&&Pattern!='测试模式'&&ComplateTest=='1'&&isJudgment=='1' " style="font-size:18px;display:inline-block;vertical-align:middle;">打分中</span> -->
                    </div>
                    <Time :Pattern='Pattern'/>
                </div>
            </div>
        </div>



        <!-- <div class="Machine" v-if="Pattern!='测试模式'">
            <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
            <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
            <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
            <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
            <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
            <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
            <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
            <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="CheckKey()">
            <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
        </div> -->

        <popup :show.sync="pshow" :describe='this.Target.description'></popup>
    </div>
</template>

<script>
    import Head from '../../components/head.vue'
    import videoJs from 'video.js'
    import "video.js/dist/video-js.min.css"
    export default {
        data(){
            return {
                // 机器人按钮显示
                mshow:{
                    text:1,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                // 数据加载 判断 防止二次加载
                Panduan:true,
                // 章节信息
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                // 左右 切换按钮显示
                RightLeft:false,
                // 描述 弹窗显示
                pshow:false,

                // 问题 数据
                questionData:{},
                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0, // 当前子题
                childQuestionListAll:0, // 所有子题 长度
                AnswerKey:'',
                childQuestionData:{},
                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 播放器
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                check:'', // 判断是否选择 答案
                complate:false, // 是否提交答案
                answer:'', // 答案

                NoteItem:{//笔记
                    id:'',
                    content:'',
                },
                // 绑题规则
                BindRole:{},

                // 树形 上层数据
                UpLayerData:{},

                InfoComplate:false, // 首次加载数据判断

                ProgressBar:{
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                },
                AudioPlayerUrl:"",//题干音频地址
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1,2,3,4],
                },
            }
        },
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }
        },
        components:{Head},
        mounted(){
            let Question_box = document.querySelector('.Question_box').clientHeight;
            let list = document.querySelectorAll('.Question_box>div');
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                element.style.height = (Question_box-17) + 'px';
            }
        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            checkid:function(){
                return this.$store.state.common.checkid;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            checkid:function(){
                if(this.InfoComplate){
                    this.getinfo();
                }
            },
            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
            threeActive:function(v){
                if(v=='KEY'&&!!this.CheckKey){
                    this.CheckKey();
                }
            }
        },
        methods:{
            stayPause(){ //暂停
                let d = window.PlayerState(this.player.paused());
                if(!d){
                    this.InPlay = false;
                    this.player.pause();
                }
                this.ProgressBar.show = false;
                clearInterval(this.ProgressBar.time);
                clearTimeout(this.ResetTime)
            },
            async getinfo(){
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                // 判断 学习模式
                this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName});
                this.$store.commit('setitem',{'name':'RoleIsJudgment','item': this.Target.bindRole.isJudgment});
                // this.$store.commit('setitem',{'name':'Pattern','item':'学习模式'});

                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                this.threeShow(this.BindRole);
                this.ProgressBar.duration = (this.BindRole.replyTime||15); // 作答倒计时
                this.ProgressBar.timeing = (this.BindRole.replyTime||15);

                if(this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if(this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if(this.BindRole.isReadOriginal  == 1) this.mshow.text = 1;
                else this.mshow.text = 0;

                // 判断 绑题规则 设置 notes key txt
                if(this.Target.isEnd == 1 && data.data.length>1){
                    this.RightLeft = true;
                }else{
                    this.RightLeft = false;
                }


                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                console.log(this.Target.questionList[qIndex].oldId,this.Target.sectionMain.id,"485418");
                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;  // 当前问题 数据
                this.questionData.questionText = this.questionData.questionText.replace(this.reg,'');
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数
                this.childQuestionData = this.questionData.childQuestionList[0];
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[0].id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                console.log(this.BindRole);
                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.textResource
                this.QuestionAudioPlayerUrl = this.questionAudio
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    console.log('自动播放111');
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                // let src = this.FlashUrl(this.questionData.textResource);

                // this.FlashPlay(src);
                this.InfoComplate = true;
            },

            // 答案 确认
            async confirm(){
                if(!!!this.check){
                    this.$message.warning('请选择!');
                }else{
                    this.ComplateTest = '1';
                    console.log(this.questionData.childQuestionList[this.childQuestionListIndex]);
                    let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                    this.answer = url_.data.answers;
                    this.complate = true;
                    let data = {
                        answer:this.check,
                        standardAnswer:url_.data.answers,
                        type:'7',
                        bigTitleId:this.questionData.id,
                        smallTitleId:this.childQuestionData.id,
                    }
                    this.GetScore(data);
                }
            },
            async CheckKey(){
                let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                let answerKey = '';
                if(!!url_.data.answers&&url_.data.answers!=null) answerKey = `<span>Answer</span><div>${url_.data.answers}</div> `;
                if(!!url_.data.answerKey&&url_.data.answerKey!=null) answerKey += `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;
                this.mshow.key=2;
                if(this.mshow.notes!=0) this.mshow.notes=1;
                if(this.mshow.text!=0) this.mshow.text=1;
            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g,'');
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }
                if(!!item.id){
                    let edit_ = await this.AjaxApi.updateCourseNote(item);
                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },
            //  滑块 tooltip
            formatTooltip(val){
                if(!!this.player){
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if(c!=NaN&&c!='NaN') c += 's'
                    else c = 's'
                    return c;
                }else{
                    return '';
                }
            },
            // 播放 暂停控制
            PlayPause(it){
                if(this.Pattern == '测试模式'){
                    return;
                }
                if(this.ClickLimit){
                    return false;
                }
                this.ClickLimit = true;
                let d = window.PlayerState(this.player.paused());
                if(d){
                    this.InPlay = true;
                    this.player.play();
                }else{
                    this.InPlay = false;
                    this.player.pause();
                }
                setTimeout(()=>{
                    this.ClickLimit = false;
                },1000)
            },
            // 音频播放 完成
            PlayerComplete(){
                let current = parseInt(sessionStorage.getItem('PlayTwice'));
                let {isPlayTwice,topicPlaybackInterval} = this.BindRole;
                if(isPlayTwice==1&&current<1){
                    current++;
                    sessionStorage.setItem('PlayTwice',current);
                    let time = topicPlaybackInterval || 3;
                    time = parseInt(time) * 1000;
                    setTimeout(this.ResetTime,time);
                }else{
                    if(this.Pattern != '测试模式'){
                        return;
                    }
                    this.$notify({
                        title: '开始作答',
                        message: '开始作答,作答时间' + (this.BindRole.replyTime||15) + '秒',
                        duration: 3000
                    });
                    this.ProgressBar.show = true;
                    this.ProgressBar.time = setInterval(()=>{
                        if(this.ProgressBar.duration>0){
                            this.ProgressBar.duration--;
                            let c = (this.ProgressBar.duration / this.ProgressBar.timeing) * 100;
                            this.ProgressBar.width = c;
                        }else{
                            this.ProgressBar.duration = 0;
                            this.ProgressBar.width = 0;
                            clearInterval(this.ProgressBar.time);
                            this.ProgressBar.show = false;
                            this.UpAnswer();
                        }
                    },1000);
                }
            },
            // 重新 播放
            // ResetTime(){
            //     this.audioTime = 0;
            //     this.player.volume(1);
            //     this.player.currentTime(0.5);
            //     this.player.play();
            //     this.InPlay = true;
            // },
            // 上传 答案
            async UpAnswer(){
                this.ComplateTest = '1';
                this.complate = true;
                let answer = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                this.answer = answer.data.answers;
                let Pan = '';
                if(this.answer == this.check){
                    Pan = 0;
                }else{
                    Pan = 1;
                }
                let item = {
                    childQuestionId:this.questionData.childQuestionList[this.childQuestionListIndex].id,
                    courseId:this.$store.state.common.curriculum,
                    currentAnswer:this.check,
                    isTrue:Pan,
                    nodeId:this.Target.id,
                    questionId:this.questionData.id,
                    rightAnswer:this.answer,
                };
                let updata = await this.AjaxApi.addAnswerRecord(item);
                let data = {
                    answer:this.childquestionDataCheck,
                    standardAnswer:answer.data.answers,
                    type:'7',
                    bigTitleId:this.questionData.id,
                    smallTitleId:this.childQuestionData.id,
                }
                this.GetScore(data);
                this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            // 上一题
            async prevQuestion(){
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            async nextQuestion(){
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange(){
                // 数据还原
                if(!!this.ProgressBar.time) clearInterval(this.ProgressBar.time);
                if(!!this.player)   this.player.pause();
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.ProgressBar = {
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                }
                this.ProgressBar.duration = (this.BindRole.replyTime||15); // 作答倒计时
                this.ProgressBar.timeing = (this.BindRole.replyTime||15);

                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;  // 当前问题 数据
                if (this.questionData.questionText !== null && this.questionData.questionText !== undefined) {
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg, '');
                }
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数
                this.childQuestionData = this.questionData.childQuestionList[0];
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[0].id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                 // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.textResource
                this.QuestionAudioPlayerUrl = this.questionAudio
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    console.log('自动播放111');
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                // let src = this.FlashUrl(this.questionData.textResource);
                // this.FlashPlay(src);
                this.InfoComplate = true;
            },
            On_back(){
                this.ComplateTest = 0;
                this.T_back(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            On_next(){ 
                this.ComplateTest = 0;
                if(this.$store.state.common.MaxPattern=='闯关模式'){
                    if(!!this.check&&this.check!=''&&this.complate){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }else{
                        this.$message.warning('请先完成本题!');
                    }
                }else{
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },
        },
        destroyed(){
            // 删除 播放器
            if(!!this.player){
                this.player.dispose();
            }
            clearInterval(this.ProgressBar.time);
        },
    }

</script>

<style lang="less" scoped>
</style>



import { render, staticRenderFns } from "./judgment.vue?vue&type=template&id=2c717658&scoped=true&"
import script from "./judgment.vue?vue&type=script&lang=js&"
export * from "./judgment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c717658",
  null
  
)

export default component.exports